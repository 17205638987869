<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Period</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Max 30 Days'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Distributor</label>
                    <Dropdown id="db_id" dataKey="db_id" v-model="filters.db_id" :loading="loadingDropdownDistributor"
                        :options="dataDropdownDistributor"
                        optionLabel="label" optionValue="db_id" placeholder="Select Distributor"
                        :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'filter')" />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownDistributor: false,

            // dataDropdown
            dataDropdownDistributor: null,

            // filter
            filters: {
                db_id: null,
            },
            period: [new Date(), new Date()],
            maxDate: null,
        }
    },
    mounted() {
        this.searchDropdownDistributor('');
    },
    created(){
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.ddist.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;
                        } else if (purpose == null) {
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Make sure the time range is correct or not empty');
            }
        }
    }
}
</script>

